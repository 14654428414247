<div class="header" id="header__desktop">
  <div class="header__container content__container">
    <nav>
      <ul>
        <li><a href="#anchor__tracklisting">create your tracklist</a></li>
        <li><a href="#anchor__release-infos">generate pdf</a></li>
      </ul>
    </nav>
    <div class="logo">
      <a href="#section__home">vinyl tracklisting</a>
    </div>
  </div>
</div>

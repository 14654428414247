<div class="page__container-second height-auto" id="timing_bottom">
  <a id="anchor__timing" class="anchor-offset"></a>
  <section id="section__timing" class="scroll-margin-top">
    <div class="half-hero two-columns" id="timing__hero-2">
      <div class="content__container">
        <div class="tiers" data-aos="flip-up">
          <span class="block-title">1. ENTER INFOS</span>
        </div>
        <div class="tiers" data-aos="flip-up" data-aos-delay="300">
          <span class="block-title">2. DRAG & DROP</span>
        </div>
        <div class="tiers" data-aos="flip-up" data-aos-delay="600">
          <span class="block-title">3. SORT TRACKS</span>
        </div>
      </div>
    </div>
    <div class="half-hero" id="timing__hero-1">
      <div class="content__container">
        <div id="timing_title" data-aos="fade" data-aos-delay="600">
          Perfect timing depends on cutting speed
        </div>
        <div class="timing__cards" data-aos="fade" data-aos-delay="300">
          <div class="timing__card radius box-shadow">
            <div class="timing__card_title">
              33<span class="indice-rpm">RPM</span>
            </div>
            <div class="timing__card_separator"></div>
            <div class="timing__card_texts">
              <div class="timing__card_text">
                12<span class="indice-min">inch</span> &lt; 20
                <span class="indice-min">min</span>
                <span class="approx">approx.</span>
              </div>
              <div class="timing__card_text">
                10<span class="indice-min">inch</span> &lt; 14
                <span class="indice-min">min</span>
                <span class="approx">approx.</span>
              </div>
              <div class="timing__card_text">
                7<span class="indice-min">inch</span> &lt; 5
                <span class="indice-min">min</span>
                <span class="approx">approx.</span>
              </div>
            </div>
          </div>
          <div class="timing__card radius box-shadow">
            <div class="timing__card_title">
              45<span class="indice-rpm">RPM</span>
            </div>
            <div class="timing__card_separator"></div>
            <div class="timing__card_texts">
              <div class="timing__card_text">
                12<span class="indice-min">inch</span> &lt; 12
                <span class="indice-min">min</span>
                <span class="approx">approx.</span>
              </div>
              <div class="timing__card_text">
                10<span class="indice-min">inch</span> &lt; 10
                <span class="indice-min">min</span>
                <span class="approx">approx.</span>
              </div>
              <div class="timing__card_text">
                7<span class="indice-min">inch</span> &lt; 4
                <span class="indice-min">min</span>
                <span class="approx">approx.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content__container padding_for_animation">
        <div
          class="timing__infoblock centrer"
          data-aos="fade"
          data-aos-delay="300"
        >
          <p>
            <span class="bigger-text"
              >This website allows you to evenly distribute your tracks.
              <br />
              For an optimization of the sound.
              <br />
              <br />
              You can do it manually or with our
              <a href="#page-container-tracklisting"
                ><span class="surligne">sort button</span></a
              >
            </span>
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
